<template>
  <div class="product-offer-config-field-radio" :data-product-iframe-char-value="`${value.dataset}`">
    <input
        :id="`char-${charId}-value-${value.id}`"
        type="radio"
        :value="value.id"
        v-model="modelValue"
        @change="$emit('update:modelValue', parseInt($event.target.value, 10))"
    />
    <label :for="`char-${charId}-value-${value.id}`"
           data-cursor-expand
           data-goal-yandex-click="vybiral-parametry-mebeli"
           data-goal-vk-click="vybiral-parametry-mebeli"
           data-goal-google-click="vybiral-parametry-mebeli#click"
    >
      <img :src="value.imagePath" :alt="value.title">

      <CheckedIcon class="product-offer-config-field-radio__icon"/>
    </label>
  </div>
</template>

<script>
import CheckedIcon from "../icons/CheckedIcon.vue";

export default {
  name: "ProductOfferConfigFieldRadio",
  components: {CheckedIcon},
  props: ['charId', 'modelValue', 'value'],
  emits: ['update:modelValue'],
}
</script>

<style lang="scss">

</style>