import Modal from "../../components/modal/modal";

class CartModal {
    constructor() {
        this.init();
    }

    init() {
        const link = document.createElement('a');
        link.href = '/cart-preview-modal';

        this.modal = new Modal(link, {
            closerText: '',
            theme: 'light'
        });
    }
}

document.addEventListener('openModalCart', () => {
    new CartModal();
});