require('../scss/app.scss');
require('./svg/svg.js');
require('fslightbox');
require('../fonts/TTNorms/stylesheet.css');
require('../fonts/Gilroy/stylesheet.css');

window.noZensmooth = true;

require('../vue/app');
// require('@vueform/slider/themes/default.css')

require('./sliders/sliders.js');

require('./common/true-vh');
require('./common/parallax');
require('./common/goals2');
require('./common/js-validation');
require('./common/links');
require('./common/switcher');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/text-wrap-creator');
require('./common/accordion-text-height');
require('./common/smart-label');
require('./common/hamburger');
require('./common/cursor');
require('./common/cross-rotate-link');
require('./common-map/map');
require('./common/add-goal-to-article-element');
require('./common/goal-by-form-parameter');
require('./common/photoswipe');
require('./common/installment-hint');

require('./accordion/initializator');
require('./accordion/switchable-accordion');

require('./field/phone.js');
require('./field/floating.js');
require('./field/total.js');

require('./cart/pickupPointMap');

require('./cart/cartModal');
require('./cart/addressSuggestion');

require('./payment/tinkoff-payment');

require('./map/multy-marks-map');

require('./header/index');

// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');
require('glightbox');
require('./glightbox/index');
require('./common/hover-me-too');
require('./common/hover-listener');

require('./common/follower');
require('./filters/project-filters');

require('./blog-filter/blogFilter');

require('./lightbox/lightbox');
require('./background-color-changer/index');
require('./preloader/index');
require('./order-button-sticky/index');

require('./ecommerce/ecommerce');

require('./credit.js');
require('./varioqub/varioqub')

require('./rendered-image');