import {defineStore} from "pinia";
import {notificationStore} from "./notification.store";
import cartService from "../services/cart.service";

export const cartStore = defineStore('cartOrder', {
  state: () => (
      {
        loading: false,
        checkout: null,
        successAddMessage: 'Товар успешно добавлен в корзину',
        creditData: '',
        promoApplied: false,
        panelMessages: {}
      }
  ),
  getters: {
    count(state) {
      let count = 0;
      if (state.checkout && state.checkout.items) {
        count = state.checkout.items.length;
      }
      return count;
    }
  },
  actions: {
    async fetchCheckout() {
      try {
        const result = await cartService.fetchCheckout();
        this.checkout = result.data;
        setTimeout(() => {
          document.dispatchEvent(new Event('fetchedCartItems'));
        }, 250);
      } catch (e) {
        console.log(e);
      }
    },
    async add(data) {
      try {
        const result = await cartService.add(data);
        await this.fetchCheckout();
        if (result.data.showError) {
          this.showError('Произошла ошибка');
        } else {
          this.showSuccess(this.successAddMessage);
        }
      } catch (e) {
        this.showError('Произошла ошибка');
        console.log(e);
      }
    },
    async remove(key) {
      try {
        const result = await cartService.remove(key);
        await this.fetchCheckout();
        if (result.data.showError) {
          this.showError('Произошла ошибка');
        }
      } catch (e) {
        this.showError('Произошла ошибка');
        console.log(e);
      }
    },
    async increase(key, amount) {
      try {
        const result = await cartService.increase(key, amount);
        await this.fetchCheckout();
        if (result.data.showError) {
          this.showError('Произошла ошибка');
        }
      } catch (e) {
        this.showError('Произошла ошибка');
        console.log(e);
      }
    },
    async decrease(key, amount) {
      try {
        const result = await cartService.decrease(key, amount);
        await this.fetchCheckout();
        if (result.data.showError) {
          this.showError('Произошла ошибка');
        }
      } catch (e) {
        this.showError('Произошла ошибка');
        console.log(e);
      }
    },
    async applyPromocode(promocode) {
      try {
        const result = await cartService.applyPromocode(promocode);
        if (result.data.promocodeApply.status === 'error') {
          this.showError(result.data.promocodeApply.text);
        } else {
          this.checkout = result.data.checkout;
          this.promoApplied = true;
        }
      } catch (e) {
        this.showError('Произошла ошибка');
        console.log(e);
      }
    },
    async renderedImage(data) {
      try {
        const result = await cartService.setRenderedImage(data);
        console.log('renderedImage result');
      } catch (e) {
        this.showError('Произошла ошибка');
        console.log(e);
      }
    },
    showError(message) {
      const notifications = notificationStore();
      notifications.showErrorNotification(message);
    },
    showSuccess(message) {
      const notifications = notificationStore();
      notifications.showSuccessNotification(message);
    },
    addPanelMessage(key, message) {
      this.panelMessages[key] = message;
    },
    removePanelMessage(key) {
      this.panelMessages[key] = null;
    },
  },
})