function renderedImage(imageData) {
    if (!imageData) {
        console.error('imageData cannot be empty');
        return;
    }

    document.dispatchEvent(new CustomEvent('renderedImage', {
        detail: {
            image: imageData
        }
    }));
}

window.renderedImage = renderedImage